import React, { useState } from 'react';
import { RecommendationContactEnum } from 'enums';
import { Button, FlexBox, Text } from '@eltoro-ui/components';
import { saveContactsAudience } from 'Requests/Request_Methods/contactsMethods';
import sellerImgSrc from 'assets/Images/sellers.svg';
import buyerImgSrc from 'assets/Images/buyers.svg';
import './styles.scss';

interface Props {
  kind: RecommendationContactEnum;
  count: number;
  recommendationId: string;
}

const RecommendationTile = ({ kind, count = 0, recommendationId }: Props) => {
  const [audienceCreationLoading, setAudienceCreationLoading] = useState(false);
  const handleClick = () => {
    setAudienceCreationLoading(true);
    saveContactsAudience({
      recommendation_id: recommendationId,
      contact_type: kind === RecommendationContactEnum.BUYER ? 'buyer' : 'seller',
      tags: [],
    })
      .then(res => {
        if (res.data && res.data.campaign_id) {
          window.location.pathname = `/create-campaign/${res.data?.campaign_id}/setup`;
        }
      })
      .finally(() => {
        setAudienceCreationLoading(false);
      });
  };
  return (
    <div className="RecommendationTile">
      <FlexBox alignItems="center" justifyContent="flex-start" gap="10px">
        <img
          src={kind === RecommendationContactEnum.SELLER ? sellerImgSrc : buyerImgSrc}
          alt={kind === RecommendationContactEnum.SELLER ? 'seller' : 'buyer'}
        />
        <FlexBox flexDirection="column" alignItems="start" justifyContent="center" gap="10px">
          <Text on="white" weight="bold" UNSAFE_className="title">
            {kind === RecommendationContactEnum.SELLER
              ? 'Households likely to sell'
              : 'Households likely to buy'}
          </Text>
          <Text on="white" UNSAFE_className="desc">
            {kind === RecommendationContactEnum.SELLER
              ? `${count} of your contact households who are potential sellers are displaying in-market activity over the last 90 days.`
              : `${count} of your contact households who are potential buyers are displaying in-market activity over the last 90 days.`}
          </Text>
        </FlexBox>
      </FlexBox>
      <Button
        size="l"
        kind="primary"
        weight="bold"
        UNSAFE_className="save_or_launch_btn"
        onClick={handleClick}
        disabled={false}
        loading={audienceCreationLoading}
      >
        Save & Launch
      </Button>
    </div>
  );
};

export default RecommendationTile;
